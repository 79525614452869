/*
font-family: 'Inter', sans-serif;
Thin 100
ExtraLight 200
Light 300
Regular 400
Medium 500
SemiBold 600
Bold 700
ExtraBold 800
Black 900

--------

font-family: 'Source Sans 3', sans-serif;
ExtraLight 200
ExtraLight 200 Italic
Light 300
Light 300 Italic
Regular 400
Regular 400 Italic
Medium 500
Medium 500 Italic
SemiBold 600
SemiBold 600 Italic
Bold 700
Bold 700 Italic
ExtraBold 800
ExtraBold 800 Italic
Black 900
Black 900 Italic
*/
body{
	font-family: 'Source Sans 3', sans-serif;
	color: #000;
}
img{
	max-width: 100%;
	height: auto;
}
a{	
	color: #2F80ED;
	text-decoration: none;
	transition: all .2s linear;
}
a:hover{
	color: #CF152D;
}
/****** create profile page start******/
.main_cont_pos{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
}
.logo_main {
	margin-bottom: 40px;
}
.head_1{
	font-size: 150px;
	color: #CF152D;
	font-family: 'Inter', sans-serif;
	font-weight: 900;
	margin-bottom: 0;
}
.head_1 .head_wel{
	font-size: 160px;
	color: #062F87;
	padding-top: 20px;
	display: inline-block;
}
.create_porfile {
    font-size: 78px;
    font-weight: 700;
    padding-top: 120px;
    padding-bottom: 60px;
}
.create_porfile span {
    padding-left: 63px;
    display: inline-block;
    vertical-align: middle;
}
.form_common .form-control{
	padding: 40px;
	font-size: 24px;
	box-shadow: none;
}
.form_common .form_group .form-label {
    position: absolute;
    left: 83px;
    top: 42px;
    z-index: 9;
    font-size: 24px;
    color: #646564;
    margin: 0;
    pointer-events: none;
    transition: all .2s linear;
}
.form_common .form_group .form-label span{
	color: #C7C6C1;
	font-size: 20px;
}
.form_common .group_with_icon .form-control{
	padding-left: 15px;
	background: #fff;
	border: 1px solid #C7C6C1;
	border-radius: 0 15px 15px 0 !important;
	border-left: 0;
}
.form_common .group_with_icon .input-group-text{
	padding: 40px 0px 40px 40px;
	background: #fff;
	border: 1px solid #C7C6C1;
	border-radius: 15px 0 0 15px !important;
	border-right: 0;
}
.form_common .form_group.focus_label .form-label{
	top: 10px;
	font-size: 18px;
}
.form_common .form_group.focus_label .form-label span{
	font-size: 16px;
}
.form_common .form_group{
	margin-bottom: 25px;
}
.form_common .custom_check {
    font-size: 28px;
    position: relative;
    padding-left: 58px;
    margin-bottom: 30px;
}
.form_common .custom_check input[type="checkbox"]{
	opacity: 0;
}
.form_common .custom_check input[type="checkbox"] ~ span {
    height: 36px;
    width: 36px;
    border: 2px solid #C7C6C1;
    border-radius: 10px;
    display: flex;
    position: absolute;
    left: 0;
    top: 2px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    pointer-events: none;
}
.form_common .custom_check input[type="checkbox"] ~ span img{
	opacity: 0;
	width: 20px;
	height: 15px;
}
.form_common .custom_check input[type="checkbox"]:checked ~ span img{
	opacity: 1;
}
.form_common .custom_check input[type="checkbox"]:checked ~ span{
	background: #70C059;
	border-color: #70C059;
}
.btn_common{
	background: #6EC059;
	padding: 24px 140px;
	font-size: 50px;
	font-weight: 700;
	border-radius: 60px;
	color: #fff;
	line-height: 1.3;
	border: 0;
}
.btn_common img{
	margin-right: 20px;
}
.btn_common:hover{
	background: #062F87;
	color: #fff;
}
.create_profile_form .form_btn_grp,
.varify_ac_form .form_btn_grp{
	padding-top: 80px;
}
.btn_grp_link {
    font-size: 40px;
    font-weight: 700;
    color: #0E9648;
    margin-top: 035px;
    display: inline-block;
}
.group_with_icon .input-group-text img{
	width: 32px;
	height: 32px;
}
/****** create profile page end******/


/****** sync device page start *******/
.varify_ac_right{
	width: 894px;
	margin: 0 auto;
	max-width: 100%;
}
.head_2{
	font-size: 78px;
	font-weight: 700;
	margin-bottom: 15px;
}
.varify_ac_right p{
	font-size: 40px;
	line-height: 54px;
	margin-bottom: 15px;
}
.varify_ac_right h4 {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 60px;
}
.varify_fields_row .form-control{
	height: 142px;
	width: 142px;
	background: #fff;
	border: 3px solid #14A853;
	border-radius: 15px;
	font-size: 100px;
	text-align: center;
	line-height: normal;
}
.verify_account_block{
	padding-top: 160px;
}
.varify_fields_row .col-auto{
	padding-left: 25px;
	padding-right: 25px;
}

.varify_fields_row .form-control[type=number]::-webkit-outer-spin-button,
.varify_fields_row .form-control[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.varify_fields_row .form-control[type=number] {
  -moz-appearance: textfield;
}
/****** sync device page end *******/

/****** varify account page start ******/
.vaify_ac_cont .head_2{
	margin-bottom: 0;
}
.vaify_ac_cont p{
	font-size: 40px;
	margin-bottom: 0;
}
.vaify_ac_cont {
    margin: 100px 0 50px;
}
/****** varify account page end ******/

/*********** Home page start ************/
.welcome_box .head_1{
	font-size: 120px;
	color: #000;
	font-weight: 700;
}
.user_location{
	font-size: 50px;
}
.user_location img{
	width: 62px;
	height: 62px;
}
.welcome_btns li{
	display: inline-block;
	vertical-align: middle;
	margin: 0 10px;
}
.welcome_btns li a{
	font-size: 40px;
	font-weight: 700;
	color: #0E9648;
	background: #EFF9EC;
	padding:23px 60px;
	border-radius: 50px;
	line-height: 1.3;
	display: block;
}
.welcome_btns li a img {
    margin-right: 25px;
    width: 44px;
    height: 44px;
}
ul.welcome_btns {
    padding: 0;
    margin: 40px 0 0;
}
.welcome_btns li a:hover{
	background: #062f87;
	color: #fff;
}
.checkin_timer {
    background: #fff;
    border-radius: 500px;
    margin-top: 80px;
    padding: 30px;
}
/** timer css start **/
.base-timer {
    position: relative;
    width: 346px;
    height: 346px;
 	margin: auto;
}
.base-timer__svg {
    transform: scaleX(-1);
}
.base-timer__circle {
    fill: none;
    stroke: none;
}
.base-timer__path-elapsed {
    stroke-width: 6px;
    stroke: rgba(0,0,0,0.1);
}
.base-timer__path-remaining {
    stroke-width: 4px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
}
.base-timer__path-remaining.green {
    color: #39b37d;
}
.base-timer__path-remaining.orange {
    color: orange;
}
.base-timer__path-remaining.red {
    color: red;
}
.base-timer__label {
    position: absolute;
    width: 266px;
    height: 266px;
    top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 75px;
    background: rgba(0,0,0,0.05);
    border-radius: 50%;
    left: 40px;
    color: #2F80ED;
    font-weight: 700;
}
/** timer css end **/
.checkin_right {
    text-align: left;
}
.checkin_right h3{
	font-size: 80px;
	font-weight: 700;
}
.checkin_right h3 span {
    font-size: 40px;
    font-weight: 400;
    opacity: 0.5;
    display: inline-block;
    vertical-align: middle;
}
.checkin_right p{
	font-size: 40px;
	letter-spacing: -0.15px;
}
.checkin_btns .btn_common {
    font-size: 24px;
    padding: 20px 50px;
}
.checkin_btns .btn_common:first-child{
	margin-right: 16px;
}
.chck_in_alert {
    font-size: 28px;
    color: #CF7F2F;
    letter-spacing: -0.23px;
    font-weight: 600;
    margin-top: 15px;
}
.check_in_wrap .container{
	max-width: 1790px;
}
.banners_main {
    padding-top: 80px;
    padding-bottom: 30px;
}
.page_menu_btns{
	background: #fff;
	border-radius: 20px;
	padding: 15px;
	list-style: none;
}
.page_menu_btns li{
	/*display: inline-block;
	vertical-align: middle;*/
	margin: 0 3px;
}
.page_menu_btns li a {
    font-size: 38px;
    color: #646564;
    letter-spacing: -0.15px;
    border: 3px solid #6465644D;
    border-radius: 80px;
    padding: 15px 40px;
    display: inline-block;
}
.page_menu_btns li a svg{
	margin-right: 15px;
	fill: #646564;
} 
.page_menu_btns li.active a svg,
.page_menu_btns li:hover svg{
	fill:#70C059;
}
.page_menu_btns li.active a,
.page_menu_btns li:hover a{
	color: #0E9648;
	border-color:#70C0594D;
	background: #70C0594D;
}

/*********** Home page end ************/

/*********** launch page start ************/

.strip_item {  
    display:block;
    color: #fff;
    font-size: 11.5vw;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 25px;
}
.strip_item span{
	display: inline-block;
	background: #000;
	padding: 3px 10px;
}
.strip_item:nth-child(2) span{
	background: #740937;
}

.tab_button {
    height: 500px;
    width: 500px;
    border-radius: 50%;
    margin: 240px auto 30px;
    position: relative;
    z-index: 4;
}
.tab_button > a{
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    border-radius: 50%;
    margin: 0px auto 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.2;
}
.btn_bg_img{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(red, yellow);
	z-index: 1;
	border-radius: 50%;
	animation: animName 3s linear infinite;
}
@keyframes animName {
 0%{
    transform: rotate(0deg) scale(1.0);
   }
50%{
    transform: rotate(180deg) scale(1.1);
   }
 100%{
	transform: rotate(360deg) scale(1.0);
 }
}
.rewards_timeline_main{
	padding:40px 60px;
	background: rgb(223,229,242);
	background: linear-gradient(180deg, rgba(223,229,242,1) 0%, rgba(253,254,253,1) 100%);
	border-radius: 40px;
}
.reward_txt {
    font-size: 54px;
    font-weight: 700;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    top: 0;
    color: #a48bc8;
}
.reward_txt span{
	display: block;
	font-size: 20px;
	font-weight: 400;
	color: #838383;
}
.reward_item {
    position: relative;
}
.reward_shape {
    min-height: 110px;
    padding-left: 160px;
    text-align: left;
}
.reward_shape_in{
	border-radius: 60px;
	background: #a48bc8;
	padding: 20px;
	font-size: 40px;
	font-weight: 800;
}
.reward_shape_in {
    border-radius: 60px;
    background: #a48bc8;
    padding: 10px;
    font-size: 34px;
    font-weight: 800;
    display: inline-block;
    text-transform: uppercase;
    position: relative;
}
.reward_shape_in figure {
    position: absolute;
    left: -10px;
    top: 0px;
    height: 120px;
    width: 120px;
    background: #a48bc8;
    border-radius: 50%;
    z-index: 4;
}
.reward_shape_in {
    border-radius: 60px;
    background: #a48bc8;
    padding: 20px 35px 20px 120px;
    font-size: 34px;
    font-weight: 800;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
}
.reward_item:nth-child(3) .reward_shape_in{
	font-size: 18px;
	font-weight: 600;
	text-align: left;
}
.reward_item:nth-child(2) .reward_shape_in,
.reward_item:nth-child(2) .reward_shape_in figure{
	background: #c2c2c2;
}
.reward_item:nth-child(3) .reward_shape_in,
.reward_item:nth-child(3) .reward_shape_in figure{
	background: #c8b72f;
}
.reward_item:nth-child(4) .reward_shape_in,
.reward_item:nth-child(4) .reward_shape_in figure{
	background: #838383;
}
.reward_item:nth-child(5) .reward_shape_in,
.reward_item:nth-child(5) .reward_shape_in figure{
	background: #e9a727;
}
.reward_item + .reward_item{
	margin-top: 70px;
}

.reward_shape_in figure:after {
    left: -36px;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(164, 139, 200, 0);
    border-right-color: #a48bc8;
    border-width: 20px;
    margin-top: -20px;
}
.reward_item:nth-child(2) .reward_shape_in figure:after{
	border-right-color: #c2c2c2;
}
.reward_item:nth-child(3) .reward_shape_in figure:after{
	border-right-color: #c8b72f;
}
.reward_item:nth-child(4) .reward_shape_in figure:after{
	border-right-color: #838383;
}
.reward_item:nth-child(5) .reward_shape_in figure:after{
	border-right-color: #e9a727;
}
.reward_item:nth-child(2n) {
    margin-left: 15%;
}

.reward_item:nth-child(2) .reward_txt{
	color: #c2c2c2;
}
.reward_item:nth-child(3) .reward_txt{
	color: #c8b72f;
}
.reward_item:nth-child(4) .reward_txt{
	color: #838383;
}
.reward_item:nth-child(5) .reward_txt{
	color: #e9a727;
}
.reward_shape_in:after {
    content: "";
    position: absolute;
    left: 100px;
    top: 100%;
    height: 150px;
    width: 8px;
    background: #9c9fa0;
    transform: rotate(-35deg);
}
.reward_item:nth-child(2n) .reward_shape_in:after {
    transform: rotate(35deg);
    left: -30px;
}
.reward_item:last-child .reward_shape_in:after{
	display: none;
}
.cir_icon > span{
	display: block;
	height: 100px;
	width: 100px;
	margin-left: 10px;
	margin-top: 10px;
	background: #fff;
	border-radius: 50%;
}
.reward_discount {
    background: #fff;
    border-radius: 20px;
    padding: 10px 15px;
    font-size: 40px;
    font-weight: 700;
    color: #6EC059;
    margin-bottom: 40px;
    text-align: center;
}
.reward_count_main figure {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: -11px 15px 0 0;
}
.reward_count_main figure .reward_count{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	text-align: center;
	color: #fff;
	font-size: 22px;
	font-weight: 700;
	color: #fff;
	padding: 5px;
	line-height: 45px;
}
.reward_count_main {
    text-align: left;
    font-size: 46px;
    font-weight: 700;
    line-height: 1;
}
.reward_count_main{
	margin-bottom: 40px;
}
.reward_block {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-bottom: 30px;
}
.reward_header{
	background: #904737;
	padding: 40px 40px;
	text-align: left;
	position: relative;
}
.reward_header h2{
	margin: 0;
	font-size: 100px;
	font-weight: 500;
	color: #fff;
	line-height: 1;
	padding-right: 200px;
}
.reward_header h2 span {
    display: block;
    font-size: 38px;
    font-weight: 700;
}
.reward_medal{
	position: absolute;
	right: 40px;
	top: -1px;
	z-index: 2;
}
.reward_ttl{
	font-size: 46px;
	font-weight: 700;
	margin-bottom: 15px;
}
.rewards_redeem_right {
    text-align: left;
    max-height: 900px;
    overflow: auto;
    padding-right: 10px;
}
.reward_date{
	font-size: 18px;
	margin-bottom: 15px;
}
.reward_name{
	font-size: 28px;
	font-weight: 700;
	color: #666;
}

.reward_block_info .btn_common {
    padding: 20px 40px;
    font-size: 29px;
    text-transform: uppercase;
    font-weight: 600;
}
.reward_block_info .btn_common svg {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    margin-top: -3px;
}
.reward_block_info {
    padding: 20px 40px;
    position: relative;
    padding-right: 220px;
    min-height: 300px;
    background: rgba(255,255,255,0.5);
}
.reward_date img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.reward_val {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 240px;
    text-align: center;
    padding-bottom: 20px;
    font-size: 70px;
    font-weight: 700;
    line-height: 1;
}
.reward_val span{
	font-weight: normal;
	display: block;
	padding-top: 10px;
	font-size: 18px;
}

/* scrollbar */
.rewards_redeem_right::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.rewards_redeem_right::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.rewards_redeem_right::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.rewards_redeem_right::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/*********** launch page end ************/
.input-group .error {
    color: red;
    width: 100%;
    text-align: left;
    font-size: 25px;
    padding: 2px 40px;
}
.input-group .otp-error {
    color: red;
    width: 100%;
    padding: 10px 0px;
    font-size: 25px;
    text-align: center;
}
/* .input-group.form_group.group_with_icon.custom-date-picker input.form-control {
    width: 99%;
    margin-left: 0;
    padding-left: 10px;
} */
.custom-picker {
    display: block;
}
.input-group.form_group.group_with_icon.custom-date-picker {
    display: block;
    text-align: left;
}
.input-group.form_group.group_with_icon.custom-date-picker input.form-control {
    width: 100%;
    margin-left: 0;
    border-radius: 10px !important;
    border: 1px solid #C7C6C1;
    padding-left: 12%;
    padding-top: 6%;
}
.input-group.form_group.group_with_icon.custom-date-picker .react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
    width: 100%;
}
.input-group.form_group.group_with_icon.custom-date-picker span.input-group-text-icon {
    position: absolute;
    top: 58px;
    z-index: 9;
    left: 40px;
    margin: auto;
    transform: translateY(-50%);
}
.custom-picker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .custom-picker .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    left: -27px;
}
.custom-picker .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .custom-picker .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
    left: -27px;
}
.custom-picker .react-datepicker-popper{
    z-index: 99;
}
.custom-picker .react-datepicker__header{
    background-color: #e2edf7;
}
.custom-picker .react-datepicker__month-read-view--down-arrow, .custom-picker .react-datepicker__year-read-view--down-arrow{
    top: 4px;
}
.custom-picker .react-datepicker__year-dropdown, .custom-picker .react-datepicker__month-dropdown, .custom-picker .react-datepicker__month-year-dropdown{
    background-color: #e2edf7;
}
.custom-picker .react-datepicker{
    font-family: 'Source Sans 3', sans-serif !important;
}
@media (min-width: 1460px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1425px;
    }
	.custom-picker .react-datepicker__day-name, .custom-picker .react-datepicker__day, .custom-picker .react-datepicker__time-name{
		width: 3rem;
		line-height: 3rem;
	}
	.custom-picker .react-datepicker{
		font-size: 1rem;
	}
	.custom-picker .react-datepicker__navigation--next, .custom-picker .react-datepicker__navigation--previous{
		top: 10px;
	}
}/* min-width:1199 end*/
@media (max-width: 1650px){
	.reward_shape_in{
		font-size: 26px;
	}
	.reward_item:nth-child(3) .reward_shape_in{
		font-size: 16px;
	}
	.reward_txt {
    	line-height: 1.3;
	    font-size: 44px;
	}
}
@media (max-width: 1459px){
	.logo_main img{
		max-width: 700px;
	}
	.head_1{
		font-size: 90px;
	}
	.head_1 .head_wel{
		font-size: 100px;
		padding-top: 0;
	}
	.create_porfile span{
		padding-left: 30px;
	}
	.create_porfile{
		font-size: 50px;
		padding-top: 60px;
    	padding-bottom: 30px;
	}
	.create_porfile img {
	    width: 100px;
	    height: 100px;
	}
	.logo_main {
	    margin-bottom: 0px;
	}
	.form_common .group_with_icon .input-group-text{
		padding:25px 0px 25px 25px ;
	}
	.input-group.form_group.group_with_icon.custom-date-picker span.input-group-text-icon{
		left:0;
		padding:0 0px 30px 15px;
		top:46px;
	}
	.input-group.form_group.group_with_icon.custom-date-picker input.form-control {
		padding-left: 54px;
		padding-top: 22px;
	}
	.input-group .error {
		font-size: 16px;
		padding: 2px 20px;
	}
	.input-group .otp-error {
		font-size: 16px;
		padding: 2px 20px;
	}
	.form_common .form-control{
		padding: 25px;
		font-size: 20px;
	}
	.form_common .form_group .form-label {
	    left: 70px;
	    top: 28px;
	    font-size: 20px;
	}
	.form_common .form_group.focus_label .form-label {
	    top: 5px;
	    font-size: 14px;
	}
	.form_common .custom_check{
		font-size: 20px;
		padding-left: 38px;
		margin-bottom: 20px;
	}
	.form_common .form_group .form-label span {
	    font-size: 17px;
	}
	.form_common .custom_check input[type="checkbox"] ~ span {
	    height: 28px;
	    width: 28px;
	    border: 1px solid #C7C6C1;
	    border-radius: 5px;
	    top: 0px;
	}
	.btn_common {
	    padding: 15px 50px;
	    font-size: 30px;
	}
	.btn_common img {
	    margin-right: 10px;
	    width: 30px;
	    height: 30px;
	}

	.head_2{
		font-size: 50px;
	}
	.varify_ac_right p {
	    font-size: 32px;
	    line-height: 42px;
	}
	.varify_ac_right h4{
		font-size: 34px;
	}
	.btn_grp_link{
		font-size: 26px;
	}
	.btn_grp_link{
		margin-top: 25px;
	}
	.varify_fields_row .form-control {
	    height: 110px;
	    width: 110px;
	    font-size: 86px;
	}
	.verify_account_block {
	    padding-top: 70px;
	}
	.varify_fields_row .col-auto{
		padding-left: 15px;
		padding-right: 15px;
	}
	.vaify_ac_cont {
	    margin: 50px 0 20px;
	}
	.vaify_ac_cont p {
	    font-size: 26px;
	}
	.create_profile_form .form_btn_grp, .varify_ac_form .form_btn_grp {
	    padding-top: 50px;
	}
	.page_menu_btns li a{
		padding: 10px 30px;
		font-size: 30px;
	}
	.page_menu_btns li a svg {
	    margin-right: 10px;
	    width: 30px;
	    height: 30px;
	}
	.checkin_right p{
		font-size: 30px;
	}
	.chck_in_alert{
		font-size: 20px;
	}
	.welcome_box .head_1{
		font-size: 80px;
	}
	.user_location {
	    font-size: 35px;
	}
	.rewards_timeline_main {
	    padding: 30px 20px;
	}
	.reward_shape_in figure{
		height: 100px;
		width: 100px	;
	}
	.cir_icon > span{
		height: 80px;
		width: 80px;
	}
	.reward_shape_in {
	    padding: 15px 25px 15px 90px;
	    font-size: 24px;
	}
	.reward_item + .reward_item {
	    margin-top: 20px;
	}
	.reward_header h2{
		padding-right: 140px;
		font-size: 60px;
	}
	.reward_header h2 span {
	    font-size: 24px;
	    font-weight: 600;
	}
	.reward_medal{
		width: 130px;
	}
	
	.reward_ttl{
		font-size: 32px;
	}
	.reward_name{
		font-size: 22px;
	}
	.reward_block_info{
		padding-right: 170px;
		min-height: 260px;
	}
	.reward_val{
		font-size: 60px;
	}
	.reward_val{
		width: 200px;
	}
	.rewards_redeem_right {
	    max-height: 710px;
	}
	.tab_button{
		margin-top: 180px;
	}
}/* max-width:1459  end*/

@media (max-width: 1199px){
	.logo_main img {
	    max-width: 400px;
	}
	.head_1 {
	    font-size: 50px;
	}
	.head_1 .head_wel {
	    font-size: 60px;
	    padding-top: 0;
	}
	.create_porfile {
	    font-size: 30px;
	    padding-top: 30px;
	    padding-bottom: 20px;
	}
	.create_porfile span {
	    padding-left: 10px;
	}
	.create_porfile img {
	    width: 60px;
	    height: 60px;
	}

	.btn_grp_link {
	    font-size: 24px;
	    margin-top: 20px;
	}
	.btn_common{
		font-size: 20px;
	}
	.form_common .custom_check input[type="checkbox"] ~ span img{
		width: 15px;
		height: 10px;
	}
	.head_2 {
	    font-size: 40px;
	}
	.varify_ac_right p {
	    font-size: 24px;
	    line-height: 32px;
	}
	.varify_ac_right h4 {
	    font-size: 30px;
	    margin-bottom: 40px;
	}
	.varify_fields_row .form-control {
	    height: 80px;
	    width: 80px;
	    font-size: 64px;
	}
	.varify_fields_row .col-auto{
		padding-left: 10px;
		padding-right: 10px;
	}
	.vaify_ac_cont p {
	    font-size: 22px;
	}
	.page_menu_btns li a{
		padding: 10px 15px;
		font-size: 20px;
	}
	.checkin_right h3 {
	    font-size: 60px;
	}
	.checkin_right h3 span{
		font-size: 30px;
	}
	.checkin_right p {
	    font-size: 22px;
	}
	.checkin_btns .btn_common {
	    font-size: 20px;
	    padding: 15px 30px;
	}
	.reward_txt{
		font-size: 30px;
	}
	.reward_txt span{
		font-size: 16px;
	}
	.reward_shape{
		padding-left: 120px;
		min-height: 80px;
	}
	.reward_shape_in {
	    padding: 15px 15px 15px 75px;
	    font-size: 17px;
	}
	.cir_icon > span {
	    height: 60px;
	    width: 60px;
	}
	.reward_shape_in figure {
	    height: 80px;
	    width: 80px;
	}
	.reward_shape_in figure:after {
	    left: -25px;
	    border-width: 14px;
	    margin-top: -14px;
	}
	.reward_item:nth-child(3) .reward_shape_in{
		font-size: 14px;
	}
	.reward_shape_in:after {
	    left: 50px;
	    height: 60px;
	}
	.reward_item:nth-child(2n) .reward_shape_in:after{
		left: -10px;
	}
	.reward_block_info .btn_common {
	    padding: 12px 30px;
	    font-size: 20px;
	}
	.reward_val {
	    font-size: 50px;
	}
	.reward_header{
		padding: 20px;
	}
	.reward_medal{
		right: 20px;
	}
	.reward_block_info{
		padding-left: 20px;
		padding-right: 130px;
		min-height: 230px;
	}
	.reward_val {
	    width: 120px;
	}
	.reward_header h2{
		font-size: 40px;
		padding-right: 90px;
	}
	.reward_header h2 span{
		font-size: 20px;
	}
	.reward_medal {
	    width: 80px;
	}
	.reward_count_main{
		font-size: 36px;
	}
	.rewards_redeem_right {
	    max-height: 580px;
	}
	.tab_button{
		width: 300px;
		height: 300px;
		margin-top: 160px;
	}


}/* max-width:1199 end*/

@media (max-width: 991px){
	.logo_main img {
	    max-width: 300px;
	}
	.head_1, .head_1 .head_wel {
	    font-size: 40px;
	}
	.create_porfile img {
	    width: 40px;
	    height: 40px;
	}
	.create_porfile {
	    font-size: 20px;
	    padding-top: 20px;
	    padding-bottom: 10px;
	}
	.form_common .form_group {
	    margin-bottom: 15px;
	}
	.form_common .custom_check{
		font-size: 14px;
		padding-top: 5px;
	}
	.form_common .form_group .form-label,
	.form_common .form-control{
		font-size: 15px;
	}
	.form_common .group_with_icon .input-group-text {
	    padding: 15px 0px 15px 15px;
	}
	.form_common .form-control{
		padding: 15px;
	}
	.form_common .group_with_icon .form-control{
		padding-left:8px;
	}
	.form_common .form_group .form-label {
	    left: 54px;
	    top: 22px;
	}
	.form_common .form_group .form-label span,
	.form_common .form_group.focus_label .form-label span{
		font-size: 14px;
	}
	.btn_grp_link {
	    font-size: 20px;
	    margin-top: 20px;
	}
	.varify_ac_right h4 {
	    font-size: 26px;
	    margin-bottom: 30px;
	}
	.head_2 {
	    font-size: 34px;
	}
	.varify_ac_right p{
		font-size: 22px;
	}
	.varify_fields_row .form-control {
	    height: 70px;
	    width: 70px;
	    font-size: 50px;
	    border-width: 2px;
	}
	.page_menu_btns li a svg{
		margin-right: 5px;
	}
	.page_menu_btns li a{
		font-size: 16px;
	}
	.page_menu_btns li a svg{
		height: 20px;
		width: 20px;
	}
	.page_menu_btns li a{
		border-width: 2px;
	}
	.checkin_timer {
	    border-radius: 30px;
	    padding: 20px;
	}
	.checkin_right {
	    text-align: center;
	    padding: 20px 0 10px;
	}
	.welcome_box .head_1 {
	    font-size: 50px;
	}
	.user_location {
	    font-size: 26px;
	}
	.welcome_box .head_1{
		margin-bottom: 10px;
	}
	.welcome_btns li a {
		font-size: 24px;
	}
	.welcome_btns li a img {
	    margin-right: 10px;
	    width: 34px;
	    height: 34px;
	}
	.welcome_btns li a{
		padding: 20px 30px;
	}
	.welcome_btns li{
		margin: 0 3px;
	}
	.rewards_timeline_main {
	    width: 470px;
	    max-width: 100%;
	    margin: 0 auto;
	}
	.checkin_btns .btn_common, .checkin_btns .btn_common:first-child{
		margin: 5px;
	}
	.reward_discount{
		font-size: 30px;
	}
	.reward_count_main{
		font-size: 28px;
	}
	.reward_ttl {
	    font-size: 26px;
	}
	.tab_button{
		margin-top: 60px;
	}
}/* max-width:991 end*/

@media (max-width: 767px){
	.body_bg_img{
		display: none;
	}
	.main_cont_pos{
		position: inherit;
		height: auto;
		padding-bottom: 110px;
	}
	body{
		background-size: cover;
		background-position: center;
		background-attachment: fixed;
	}
	.head_1, .head_1 .head_wel {
	    font-size: 34px;
	}
	.varify_left_img{
		display: none;
	}
	.verify_account_block {
	    padding-top: 30px;
	}
	.head_2 {
	    font-size: 24px;
	    margin-bottom: 10px;
	}
	.varify_ac_right p {
	    font-size: 16px;
	    line-height: 24px;
	    margin-bottom: 10px;
	}
	.varify_ac_right h4{
		font-size: 20px;
	}
	.varify_fields_row .col-auto {
	    padding-left: 5px;
	    padding-right: 5px;
	}
	.varify_fields_row .form-control {
	    height: 60px;
	    width: 60px;
	    font-size: 40px;
	}
	.vaify_ac_cont p {
	    font-size: 18px;
	    padding-top: 10px;
	}
	.vaify_ac_cont {
	    margin: 20px 0 10px;
	}
	.page_menu_btns{
		display: block !important;
	}
	.page_menu_btns li{
		display: inline-block;
		vertical-align: middle;
		margin: 3px 1px;
	}
	.page_menu_btns{
		padding:10px 5px;
	}
	.welcome_box .head_1 {
	    font-size: 36px;
	}
	.base-timer{
		height: 316px;
		width: 316px;
	}
	.base-timer__label{
		width: 236px;
		height: 236px;
	}
	.checkin_btns .btn_common:first-child,
	.checkin_btns .btn_common{
		margin: 5px;
	}
	.welcome_btns li a{
		font-size: 28px;
	}
	.checkin_timer{
		margin-top: 40px;
	}
	.banners_main{
		padding-top: 40px;
	}
	.user_location{
		font-size: 20px;
	}
	.launch_logo{
		width: 190px;
	}
	.tab_button {
	    height: 170px;
	    width: 170px;
	}
		
	.tab_button > a{
		font-size: 17px;
	}
	.rewards_timeline_main {
	    padding: 20px 15px;
	    border-radius: 20px;
	}
	.rewards_timeline_main {
	    width: 370px;
	}
	.reward_txt {
	    font-size: 22px;
	}
	.reward_txt span {
	    font-size: 13px;
	}
	.reward_item:nth-child(2n) {
	    margin-left: 0;
	}
	.cir_icon > span {
	    height: 40px;
	    width: 40px;
	}
	.reward_shape_in figure {
	    height: 50px;
	    width: 50px;
	    left: -4px;
	}
	.cir_icon > span {
	    margin-left: 5px;
	    margin-top: 5px;
	}
	.reward_shape_in figure:after {
	    left: -16px;
	    border-width: 10px;
	    margin-top: -10px;
	}
	.reward_shape_in {
	    padding: 7px 10px 7px 50px;
	    font-size: 16px;
	}
	.reward_item:nth-child(3) .reward_shape_in {
	    font-size: 12px;
	    line-height: 1.2;
	    font-weight: 400;
	    padding-top: 6px;
	    padding-bottom: 6px;
	    padding-right: 15px;
	}
	.reward_shape {
	    padding-left: 80px;
	    min-height: 50px;
	}
	.reward_shape_in:after {
	    left: 18px;
	    height: 40px;
	    width: 4px;
	}
	.reward_item:nth-child(2n) .reward_shape_in:after {
	    left: 22px;
	}
	.reward_header h2{
		font-size: 30px;
		padding-right: 80px;
		line-height: normal;
	}
	.reward_medal {
	    width: 70px;
	}
	.reward_header h2 span{
		font-size: 15px;
	}
	.reward_ttl {
	    font-size: 20px;
	}
	.reward_date {
	    font-size: 15px;
	    margin-bottom: 15px;
	}
	.reward_date img {
	    margin-right: 4px;
	}
	.reward_name {
	    font-size: 16px;
	}
	.reward_block_info .btn_common {
	    padding: 12px 15px;
	    font-size: 16px;
	}
	.reward_block_info .btn_common svg {
	    width: 20px;
	    height: 20px;
	    margin-right: 2px;
	}
	.reward_val {
	    font-size: 30px;
	}
	.reward_val span {
	    padding-top: 5px;
	    font-size: 14px;
	}
	.reward_block_info{
		padding-right: 100px;
		min-height: 200px;
	}
	.rewards_redeem_right{
		max-height: initial;
		overflow: initial;
		padding-right: 0;
	}
	.check_in_wrap{
		background-size: cover;
	    background-position: center;
	    background-attachment: fixed;
	}
	.strip_item{
		margin-bottom: 15px;
	}
}/* max-width:767 end*/

@media (max-width: 575px){
	
}/* max-width:575 end*/